import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Page from "./pages/Page";
import Home from "./pages/Home";
import Catalog from "./pages/Catalog";
import AboutTrip from "./pages/AboutTrip";
import AboutUs from "./pages/AboutUs";
import ShareQr from "./pages/ShareQr";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Page />} >
          <Route index element={<Home />} />
          <Route path="catalog" element={<Catalog />} />
          <Route path="about-trip" element={<AboutTrip />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="share-qr" element={<ShareQr />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}