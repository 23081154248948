import { useLocation, Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import ToastMessage from "../components/ToastMessage";


const Page = () => {
  const location = useLocation();
  useEffect(() => {
    const hash = location["hash"]
    if (hash) {
      const dropHashSymbolRegex = /^#*(.*)/;
      const element = document.getElementById(dropHashSymbolRegex.exec(hash)[1]);
      if (element) {
        element.classList.add("hash-selected-text");
      }
      
    }

  }, [location]);


  const [toastVisible] = useState(false); //flip this to true to show the toastInnerElement below on page load
  let closeCallback = null;
  //restore this and delete above to allow single page view closes (won't persist unless we add a cookie feature)
  // const [toastVisible, setToastVisible] = useState(true);
  // let closeCallback = () => {setToastVisible(false)};


  const [toastInnerElement] = useState(<span>Checkout our <Link to="/catalog">catalog</Link> page! The final day for donations is May 17th.</span>)


  var header = null;
  const pathName = location["pathname"]
  if (pathName === "/about-trip") {
    header = <div className="banner-container">
      <img src="/about-trip-banner.jpg" className="banner-img" alt="Banner showing a painting of outlines of Texas and the United Kingdom with a dotted line connecting them."></img>
    </div>
  } else if (pathName === "/about-us") {
    header = <div className="banner-container">
      <img src="/about-us-banner.jpg" className="banner-img" alt="Banner showing a Aaron and Patience at a lake."></img>
    </div>
  } else if (pathName === "/catalog") {
    header = <div className="banner-container">
      <img src="/catalog-banner.jpg" className="banner-img" alt="Banner showing sketched watercolor examples."></img>
    </div>
  } else { //pathName === "/", or any unrecognized path
    //this is done as an else as a catch all that this banner is used in unexpected situations even before a redirect occurs and doesn't need to reload.
    header = <div className="banner-container banner-container-with-text">
      <img src="/home-banner.jpg" className="banner-img" alt="Banner showing 'Northwest to London', 'May/June 2024', overlaid on a painting of the main entrance to Northwest Bible Church."></img>
    </div>
  }


  return (
    <div className="page-container page-format-based-on-screen-size">
      {header}
      <ToastMessage visible={toastVisible} innerElement={toastInnerElement} closeCallback={closeCallback}/>
      <div className="content-container">
        <nav className="nav-bar">
          <Link to="/" className="navbar-text"><div className="nav-bar-element">Home</div></Link>
          <div className="nav-bar-separator"></div>
          <Link to="/catalog" className="navbar-text"><div className="nav-bar-element">Catalog</div></Link>
          <div className="nav-bar-separator"></div>
          <Link to="/about-trip" className="navbar-text"><div className="nav-bar-element">The Trip</div></Link>
          <div className="nav-bar-separator"></div>
          <Link to="/about-us" className="navbar-text"><div className="nav-bar-element">About Us</div></Link>
        </nav>
        <div className="copy-container"><Outlet /></div>
        <div className="footer"><Link to="/share-qr">Share</Link> | ©2024, Patience and Aaron Parks-Young</div>
      </div>
    </div>
  )
};

export default Page;
