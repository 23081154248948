import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


const Home = () => {
    return <div>
        <h1>Help Us Tell a Story</h1>
        <p><b>We commemorate events we experience and people we meet by sharing and celebrating those encounters.</b> But telling the story takes
        more than just living through it. The forging and delivery of that story changes it from historical fact into something we yearn for 
        greatly: shared human experience and with it, a window into community.
        </p>

        <img src="London_Art_PackingList_sq.jpg" className="content-container-img" alt="A watercolor painting of some items to be packed for the Northwest to London trip."></img>

        <p><b><Link to="/about-us">We're</Link> going to London this summer with some members of our 
        community, <Link to="https://northwestbible.org/">Northwest Bible Church</Link>.</b> We expect to see fascinating sites, go to places we have maybe
        only heard about or seen online, and meet some interesting people. While we don't know exactly what the trip has in store for us, we're preparing to 
        share the message and love of Jesus with asylum seekers, refugees, people in homeless communities, young adults, families, and more. If you'd 
        like to know more about the exact details of the trip and the organizations we're working with, <Link to="/about-trip">click here!</Link>
        </p>

        <img src="PatienceAaron_SaveDatePic.jpg" className="content-container-img" alt="Aaron and Patience"></img>

        <p><b>The story we're about to live through hasn't been written yet, and we've asked many people for their help.</b> As part of our adventure, we'll 
        be documenting some of what we experience and will later refine that into art which memorializes our trip. If you donated, thank you for being 
        part of the community that has made this story possible. As a thank you for donations, we'll be sending some of of the story-commemorating art to 
        our donors after the trip. See our catalog page for information 
        on how <HashLink to="/catalog#donation-delivery"> gifts for donations will be delivered.</HashLink>
        </p>
        <h2 className="center-text-horizontal">
            <Link to="/catalog">Check out our Catalog page for more info!</Link>
        </h2>
    </div>
  };
  
  export default Home;
  