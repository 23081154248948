import { useState } from 'react';

const ToastMessage = ({innerElement, visible, closeCallback = null, useAnimations=true}) => {
    const [actualVisibilityFlag, setActualVisibilityFlag] = useState(visible);

    //todo later, add classname selections for custom css

    let exitButtonIfCallback = null;
    let spacerIfCallback = null;
    if (closeCallback != null) {
        exitButtonIfCallback = <div className="toast-message-end-stub-end">
            <button onClick={closeCallback} className="toast-message-close-button">X</button>
        </div>
        spacerIfCallback = <div className="toast-message-end-stub-begin"></div>
    }

    let classNames = "toast-message"
    if (useAnimations) {
        if (visible) {
            classNames += " toast-message-open-from-top"
        } else if (!visible) {
            classNames += " toast-message-close-to-top active"
        }
    }

    return <div 
        className={classNames} 
        style={{ visibility: actualVisibilityFlag ? "visible" : "hidden" }} 
        onAnimationEnd={() => setActualVisibilityFlag(visible)}>
            {spacerIfCallback}
            <div className='toast-message-content'>{innerElement}</div>
            {exitButtonIfCallback}
    </div>
  };
  
  export default ToastMessage;
  