import { Link } from "react-router-dom";

const AboutTrip = () => {
    return <div>
        <h1>About the Trip</h1>
        <p>
            We're going to London for approximately a week to share the love of Jesus and aid in the ministries of our mission partners. From the tube 
            to the parks, we're going to be walking London with the aim of starting spiritually significant conversations with the people of London. 
            People who are homeless, asylum seekers, refugees, families, young adults &mdash; we want to make a genuine connection with people we 
            meet!
        </p>

        <div className="content-container-content-box">
            <div className="container-tile">
            <h3 className="container-title">Partners</h3>
            <div className="container-subtitle">While we're in London, we'll be supporting the ministries of our missions partners in order to have 
            conversations about Jesus.</div>
                <div className="container-tile-container">
                    <div className="container-tile-tile mobile-sensitive-max-width-container-tile">
                        <h4>Kahaila</h4>
                        <p>
                            Kahaila describes themselves as a cafe, space for community, charity, and church. Kahaila takes an active role in 
                            addressing issues facing those in the community. This has included training and empowering asylum seekers, refugees, and 
                            victims of abuse and human trafficking. 
                        </p>
                        <p>
                            Check out <Link to="https://kahaila.com/">https://kahaila.com/</Link>
                        </p>
                    </div>
                    <div className="container-tile-tile mobile-sensitive-max-width-container-tile">
                        <h4>The Kings Cross Baptist Church (KCBC)</h4>
                        <p>
                            Located in the heart of London, this church is uniquely positioned to serve the diverse population that walks by its doors 
                            every day. In their own words, they're, "A multi-cultural, multi-generational church... [seeking] to live life to the full 
                            and proclaim the great news of Jesus to our city and beyond."
                        </p>
                        <p>
                            Check out <Link to="https://www.kcbc.org.uk/">https://www.kcbc.org.uk/</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <p>Make sure to check out our partners' websites if you want to know more about them!</p>
    </div>
  };
  
  export default AboutTrip;
  