const AboutUs = () => {
    return <div>
        <h1>About Us</h1>
        <p>
            Patience is a self-employed and contract artist specializing in graphic design and watercolor. Aaron is a software developer specializing 
            in cloud-based software. Games of various forms have been a big part of Aaron and Patience's relationship. In fact, they first met at a 
            game night hosted by a mutual friend! So, it makes sense that they love spending time with friends and family, especially when good food 
            and games are involved. They were married in 2020 and live in the Dallas-Fort Worth (DFW) area.
        </p>


        <p>
            Northwest Bible Church has been Aaron and Patience's church home since 2020. Over the last few years, they have been involved in ministry 
            to refugees through the Northwest Community Center. The experiences they've had interacting with refugee families close to home has opened 
            their hearts to the needs of vulnerable populations around the world. That is one of their catalysts for this trip - the opportunity to 
            share the love of God with refugees and other hurting individuals in London. 
        </p>

        <img src="/Aaron-Patience-160.jpg" className="content-container-img-medium" alt="Patience and Aaron on their wedding day."></img>
    </div>
  };
  
  export default AboutUs;
  