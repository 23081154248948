import { Link } from "react-router-dom";

const Catalog = () => {
  
    return <div>
      <h1>Catalog</h1>
      <p>We're offering art prints as gifts for people who have helped with fundraising for our trip. While in London we'll be using a travel 
        journal with notes and sketches to capture moments we want to share. This may include the places we stay, the people we talk to, the things we 
        do, and the unique glimpses of life in London that we observe. Our hope is to share with you the adventure of travel to the UK and &mdash; 
        more importantly &mdash; to document some of what God is doing through the team.
      </p>

      <h2>How we're creating the art and gathering the stories</h2>
      <p>
      While we hope to take lots of notes, pictures, and sketches on the trip, our main focus while there will be to serve on the mission. When we 
      return home, we will edit our notes, finish the watercolor paintings, and pair the two in what we hope is a beautiful and interesting way. We'll 
      be printing the finished items on archive quality matte paper at home and package them for local pickup or shipping.
      </p>

      <h2>What we're offering</h2>
      <p>Below are categories of the gifts we're offering and suggested donation amounts for each.</p>
      <div className="content-container-content-box">
        <div className="container-tile">
          <h3 className="container-title">Journaling Prints</h3>
          <div className="container-subtitle">Prints of loose, low-detail art with notes directly on the page. Think “urban sketching” style. There will be at least 6 unique 
          designs.</div>
          <div className="container-tile-container">
            <div className="container-tile-tile">
            <h4>Sizes</h4>
              <ul>
                <li>Single, 5x7: $15</li>
                <li>Single, 8x10: $30</li>
              </ul>
              <h4>Sets</h4>
              <ul>
                <li>Set of 3, 5x7: $30</li>
                <li>Set of 3, 8x10: $60</li>
              </ul>
              <h4>Complete Sets</h4>
              <ul>
                <li>Set of at least 6, 5x7: $60</li>
                <li>Set of at least 6, 8x10: $120</li>
              </ul>
            </div>
            <img src="/Samples_Paintings_1.jpg" className="container-tile-imagetile" alt="Examples of the style for journaling prints."></img>
          </div>
        </div>
        <div className="container-tile">
          <h3 className="container-title">Art Print</h3>
          <div className="container-subtitle">Prints of fully painted images with medium to high detail. There will be at least 2 unique designs.</div>
          <div className="container-tile-container">
            <div className="container-tile-tile">
              <h4>Sizes</h4>
              <ul>          
                <li>Single, 5x7: $20</li>
                <li>Single, 8x10: $40</li>
                <li>Single, 11x14: $55</li>
              </ul>
            </div>
            <img src="/Samples_Paintings_2.jpg" className="container-tile-imagetile" alt="Examples of the style for art prints."></img>
          </div>
        </div>
        <div className="container-tile">
          <h3 className="container-title">Handbound Booklet</h3>
          <div className="container-subtitle">Handbound booklet containing all the produced artwork and stories for the project.</div>
          <div className="container-tile-container">
            <div className="container-tile-tile">
              <h4>Styles</h4>
              <ul>          
                <li>5x7: $250</li>
                <li>8x10: $350</li>
              </ul>
            </div>
            <img src="/Samples_Paintings_3.jpg" className="container-tile-imagetile" alt="Examples of the style for handbound booklets."></img>
          </div>
        </div>
      </div>

      <h2>How you'll receive your selection</h2>
      <p>
      If you've donated, email us (<Link to="mailto:parks-younglondon2024@outlook.com?subject=Northwest to London Fulfillment">parks-younglondon2024@outlook.com</Link>) 
      with a list of the thank you gifts you selected! For instance, if you wanted a set of three 5x7 journaling prints and one 11x14 painting print while 
      donating the suggested amount, you'd donate $85 dollars and then email us with that information. If you request multiple singles or a set 
      of anything, specify in your email whether you want duplicates or unique designs. Our default action will be to send you as many unique designs 
      as we can within the confines of your request and the inventory of art that we've produced. <span id="donation-delivery">We will either ship you your items or arrange for 
      pickup locally. Please expect items to be shipped around the end of July 2024 as we'll need time to refine the stories, paintings, and printing 
      process!</span>
      </p>
      
        <h2 className="center-text-horizontal">
          <Link to="mailto:parks-younglondon2024@outlook.com?subject=Northwest to London Fulfillment">Email us!</Link>
        </h2>
      
    </div>
  };
  
  export default Catalog;
  